var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.booking)?_c('section',{staticClass:"payment-details"},[_c('h1',[_vm._v(_vm._s(_vm.$t('booking.payment_screen.booking_details')))]),(_vm.booking.order && _vm.booking.order.booker)?_c('section',{staticClass:"payment-details-booker"},[_c('p',[_vm._v(_vm._s(_vm.$t('booking.payment_screen.booker_details')))]),_c('ul',{staticClass:"payment-details-booker__list"},[_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.booking.order.booker.first_name.concat( ' ', _vm.booking.order.booker.last_name ))+" ")]},proxy:true}],null,false,3475139992)}),(_vm.booking.order.booker.phone)?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.booking.order.booker.phone)+" ")]},proxy:true}],null,false,988214132)}):_vm._e(),(_vm.booking.order.booker.email)?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.booking.order.booker.email)+" ")]},proxy:true}],null,false,657301860)}):_vm._e(),(
          _vm.booking.order.order_meta &&
          _vm.booking.order.order_meta.organisation_name
        )?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.booking.order.order_meta.organisation_name)+" ")]},proxy:true}],null,false,3472396368)}):_vm._e(),(
          _vm.booking.order.order_meta && _vm.booking.order.order_meta.billing_address
        )?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.booking.order.order_meta.billing_address)+" ")]},proxy:true}],null,false,823807832)}):_vm._e()],1)]):_vm._e(),(_vm.timeline && _vm.timeline.PAY)?_c('section',{staticClass:"payment-details-payer"},[_c('form',{staticClass:"payment-details-payer__form",on:{"submit":function($event){$event.preventDefault();return _vm.submitPayer(_vm.$v.payer, _vm.formPhoneNumber)}}},[_c('div',{staticClass:"payment-details-payer-heading"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('booking.payment_screen.payer_details.payer_information'))+" ")]),_c('WMCheckbox',{attrs:{"size":"small","inline":false,"label":_vm.$t(
              'booking.manual.request.form.user.organization.label'
            )},model:{value:(_vm.$v.payer.isOrganisation.$model),callback:function ($$v) {_vm.$set(_vm.$v.payer.isOrganisation, "$model", $$v)},expression:"$v.payer.isOrganisation.$model"}})],1),_c('div',{staticClass:"payment-details-payer-content"},[_c('WMInput',{staticClass:"payment-details-payer__field",attrs:{"error":_vm.errors.fields.full_name,"required":"","label":_vm.$t(
              'booking.payment_screen.payer_details.full_name_label'
            ),"placeholder":_vm.$t(
              'booking.payment_screen.payer_details.full_name_placeholder'
            )},model:{value:(_vm.$v.payer.full_name.$model),callback:function ($$v) {_vm.$set(_vm.$v.payer.full_name, "$model", $$v)},expression:"$v.payer.full_name.$model"}}),_c('PhoneNumber',{staticClass:"payment-details-payer__field",attrs:{"error":_vm.errors.fields.phone_number,"validate":_vm.isPhoneShouldValidating,"label":_vm.$t(
              'booking.payment_screen.payer_details.phone_number_label'
            ),"placeholder":_vm.$t(
              'booking.payment_screen.payer_details.phone_number_label'
            ),"required":"","autofocused":false},on:{"validated":function (valid) { return _vm.phoneValidated(valid, _vm.formPhoneNumber, _vm.$v.payer); }},model:{value:(_vm.formPhoneNumber),callback:function ($$v) {_vm.formPhoneNumber=$$v},expression:"formPhoneNumber"}}),_c('WMInput',{staticClass:"payment-details-payer__field",attrs:{"error":_vm.errors.fields.email,"required":"","label":_vm.$t(
              'booking.payment_screen.payer_details.email_address_label'
            ),"placeholder":"john@doe.com"},model:{value:(_vm.$v.payer.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.payer.email, "$model", $$v)},expression:"$v.payer.email.$model"}}),(_vm.$v.payer.isOrganisation.$model)?_c('section',{staticClass:"payment-details-payer-organisation"},[_c('WMInput',{staticClass:"payment-details-payer__field",attrs:{"error":_vm.errors.fields.organisation,"required":"","label":_vm.$t(
                'booking.payment_screen.payer_details.organisation_name_label'
              ),"placeholder":"The Gradient B.V."},model:{value:(_vm.$v.payer.organisation.$model),callback:function ($$v) {_vm.$set(_vm.$v.payer.organisation, "$model", $$v)},expression:"$v.payer.organisation.$model"}}),_c('WMInput',{staticClass:"payment-details-payer__field",attrs:{"error":_vm.errors.fields.address,"required":"","label":_vm.$t(
                'booking.payment_screen.payer_details.organisation_address_label'
              ),"placeholder":_vm.$t(
                'booking.payment_screen.payer_details.organisation_address_placeholder'
              )},model:{value:(_vm.$v.payer.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.payer.address, "$model", $$v)},expression:"$v.payer.address.$model"}})],1):_vm._e(),(_vm.errors.general)?_c('Tip',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errors.general)+" ")]):_vm._e()],1),_c('div',{staticClass:"payment-details-payer-footer"},[_c('WMCheckbox',{staticClass:"payment-details-payer__checkbox",attrs:{"required":"","error":_vm.errors.fields.terms,"size":"small","inline":""},model:{value:(_vm.$v.payer.terms.$model),callback:function ($$v) {_vm.$set(_vm.$v.payer.terms, "$model", $$v)},expression:"$v.payer.terms.$model"}},[_c('span',{staticClass:"payment-details-payer__checkbox-label"},[_vm._v(" "+_vm._s(_vm.$t('booking.manual.request.form.user.terms.label'))+" "),_c('router-link',{staticClass:"link-dark",attrs:{"to":_vm.useGetLocalizedPath(((_vm.HelpdeskRoutes.Main) + "/general-conditions/terms-of-use")),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('booking.manual.request.form.user.terms.link'))+" ")])],1)])],1),_c('WMButton',{attrs:{"straight":"","disabled":_vm.$v.$invalid,"icon-class-name":("" + (_vm.$v.$invalid ? '' : 'fa-solid fa-arrow-right fa-lg'))}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$v.$invalid ? _vm.$t('booking.manual.request.form.actions.submit.invalid') : _vm.$t('booking.manual.request.form.actions.submit.valid'))+" ")])])],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }